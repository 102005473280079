import React from 'react';

const Paginationpagewise = ({ currentPage, itemsPerPage, paginate }) => {
  // Show only 2 pages before and after the current page
  const startPage = Math.max(currentPage - 2, 1);
  const endPage = currentPage + 2;

  const pageNumbers = [];

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination float-end">
        {/* First and Previous buttons */}
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => paginate(1)}>
            &lt;&lt;
          </button>
        </li>
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => paginate(currentPage - 1)}>
            &lt;
          </button>
        </li>

        {/* Dynamic page numbers */}
        {pageNumbers.map(page => (
          <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
            <button className="page-link" onClick={() => paginate(page)}>
              {page}
            </button>
          </li>
        ))}

        {/* Next and Last buttons */}
        <li className={`page-item`}>
          <button className="page-link" onClick={() => paginate(currentPage + 1)}>
            &gt;
          </button>
        </li>
        <li className={`page-item`}>
          <button className="page-link" onClick={() => paginate(currentPage + 2)}>
            &gt;&gt;
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Paginationpagewise;
