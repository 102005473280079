import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Card, CardBody } from "./../../components/card/card.jsx";
import Select from "react-select";
import { dateOptions } from "./../../components/selectvalues/selectvalue.jsx";
import glassshimmer from "../../assets/glassshimmer.png";
import DatePicker from "react-datepicker";

import Paginationpagewise from "./../../components/pagination/Paginationpagewise.jsx";

const Allrecipe = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [allRecipes, setAllRecipes] = useState([]); // Initialize as an empty array
  const [totalItems, setTotalItems] = useState(0); // Track total number of items

  const [receipesselectedDateOption, setReceipesSelectedDateOption] = useState(
    dateOptions[1]
  );
  const [receipescustomDateRange, setReceipeCustomDateRange] = useState(false);
  const [receipesstartDate, setReceipesStartDate] = useState(new Date());
  const [receipesendDate, setReceipesEndDate] = useState(new Date());

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Get current items with search filter applied
  const getFilteredItems = (items) => {
    // Ensure 'items' is a valid array before filtering
    if (!Array.isArray(items)) {
      return [];
    }
    return items.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const currentItems = getFilteredItems(allRecipes);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchAllRecipes(pageNumber);
  };

  // Fetch all recipes data from the API
  const fetchAllRecipes = async (page) => {
    setIsLoading(true);
    try {
        const response = await fetch(
          `https://api.barsys.ai/barsysanalytics/public/api/allRecipesData?page=${page}`
        );
        const result = await response.json();
  
        // If the result is an empty array and we're not on the first page, go back a page
        if (result.length === 0 && currentPage > 1) {
          setCurrentPage((prevPage) => prevPage - 1);
        } else {
          setAllRecipes(result || []); // Set recipes from result
          setTotalItems(result.length || 0); // Set total items
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

  useEffect(() => {
    fetchAllRecipes(currentPage); // Fetch recipes when page changes
  }, [currentPage]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      <div>
        <h6>
          <span style={{ color: "#3cd2a5" }}>PAGES</span> / ALL RECIPE
        </h6>
        <h1>All Recipes</h1>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="row">
            {/* <div className="col-md-4">
              <label htmlFor="date">Date</label>
              <Select
                options={dateOptions}
                value={receipesselectedDateOption}
                onChange={(selectedOption) =>
                  setReceipesSelectedDateOption(selectedOption)
                }
                classNamePrefix="react-select"
              />
            </div> */}
            <div className="col-md-8 mt-3 pt-1">
              <Form.Control
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search"
              />
            </div>

            <div className="col-md-2 mt-3 pt-1">
              <button className="btn btn-secondary">
                <i className="fas fa-search"></i>
              </button>
            </div>

            <div className="col-md-2 mt-4 pt-1">
              <p className="float-end">
                {totalItems === 0
                  ? "0 of 0"
                  : `${(currentPage - 1) * itemsPerPage + 1} - ${
                      (currentPage - 1) * itemsPerPage + 25
                    }`}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-12">
        <Card className="mt-3">
          <CardBody>
            <div className="table-responsive">
              {isLoading ? (
                <div className="spinner-border text-success"></div>
              ) : currentItems.length === 0 ? (
                <div>No Records Found</div>
              ) : (
                currentItems.map((product, index) => (
                  <div key={index} className={"mb-3 p-3 product_border"}>
                    <div className="row ">
                      <div className="col-xl-8 col-12 col-md-6 col">
                        <div className="d-flex align-items-center justify-content-between">
                          <div
                            className="rounded-2 d-flex align-items-center justify-content-center me-4 bg-theme text-theme-900"
                            style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                            }}
                          >
                            {indexOfFirstItem + index + 1}
                          </div>
                          <div className="position-relative">
                            <div
                              className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-60px"
                              style={{
                                backgroundImage: `url(${
                                  product.image ? product.image : glassshimmer
                                })`,
                              }}
                            />
                          </div>
                          <div className="flex-1 ps-3">
                            <div className="fw-500 text-inverse">
                              {product.name}
                            </div>
                            <div>Crafted By: {product.brandName}</div>
                            <div className="mb-1">
                              <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                                {"Barsys"}
                              </small>
                            </div>
                          </div>
                          <div className="flex-1 ms-4">
                            {/* <label>Views:</label>
                            <span className="ms-3" style={{ color: "#3CD25D", marginRight:"20px"}}>
                              {product.viewCount}
                            </span>
                            <label>Crafted:</label>
                            <span className="ms-3" style={{ color: "#3CD25D" }}>
                              {product.makeCount}
                            </span>
                            <br></br>
                            <label>Likes:</label>
                            <span className="ms-3" style={{ color: "#3CD25D" }}>
                              {product.likeCount}
                            </span> */}

                            <table
                              className={
                                index < product.length - 1 ? "mb-2" : ""
                              }
                            >
                              <tbody>
                                <tr>
                                  <td className="pe-2">Views:</td>
                                  <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                    {product.viewCount}
                                  </td>
                                  <td className="pe-2">Likes:</td>
                                  <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                    {product.likeCount}
                                  </td>
                                  <td className="pe-2">Crafted:</td>
                                  <td className="text-inverse text-opacity-75 fw-500 ">
                                    {product.makeCount}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="pe-2 text-nowrap">Clicks:</td>
                                  <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                    {0}
                                  </td>
                                  <td className="pe-2 text-nowrap">Shares:</td>
                                  <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                    {0}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-12">
                        <div>
                          <div>
                            <label>Base:</label>
                            <span className="ms-4">{product.baseList}</span>
                          </div>
                          <div>
                            <label>Mixer:</label>
                            <span className="ms-4">{product.mixerlist}</span>
                          </div>
                          <div>
                            <label>Garnish:</label>
                            <span className="ms-2">{product.garnishlist}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </CardBody>
        </Card>
        <div className="row mt-3">
          <div className="col-md-9"></div>
          <div className="col-md-3">
            <Paginationpagewise
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Allrecipe;
