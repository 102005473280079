import React, { useState, useEffect } from "react";

const JsonFormatter = () => {
  const [inputText, setInputText] = useState("");
  const [generatedJson, setGeneratedJson] = useState("");
  const [jwtToken, setJwtToken] = useState(null);

  // Inline CSS for the component
  const styles = {
    container: {
      width: "800px",
      padding: "20px",
      background: "transparent",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
      minHeight: "600px",
      margin: "0 auto",
    },
    h1: {
      marginBottom: "20px",
      fontSize: "30px",
      color: "#fff",
    },
    formGroup: {
      marginBottom: "20px",
      textAlign: "left",
    },
    label: {
      display: "block",
      marginBottom: "8px",
      fontWeight: "bold",
      color: "#fff",
    },
    textarea: {
      minWidth: "99%",
      maxWidth: "95%",
      minHeight: "150px",
      padding: "10px",
      fontSize: "14px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    textareaFocus: {
      outline: "none",
      borderColor: "#007bff",
      boxShadow: "0 0 3px #007bff",
    },
    button: {
      width: "25%",
      padding: "10px",
      fontSize: "16px",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      marginBottom: "10px",
    },
    generateBtn: {
      backgroundColor: "#007bff",
    },
    generateBtnHover: {
      backgroundColor: "#0056b3",
    },
    saveBtn: {
      backgroundColor: "#28a745",
    },
    saveBtnHover: {
      backgroundColor: "#1e7e34",
    },
  };

  // Setup JWT on component mount
  useEffect(() => {
    const fetchJwtToken = async () => {
      try {
        const response = await fetch("https://ci.api.barsys.com/api/generateJwtToken", {
          method: "GET",
          headers: {
            accessToken: "BarsysWebAPP",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch access token!");
        }

        const data = await response.json();
        setJwtToken(data.msg); // Set the new JWT token
        console.log("Access Token:", data.msg);
      } catch (error) {
        console.error("Error fetching JWT Token:", error.message);
      }
    };

    fetchJwtToken();
  }, []);

//   const generateRSAKeyPair = async () => {
//     try {
//       const keyPair = await window.crypto.subtle.generateKey(
//         {
//           name: "RSA-OAEP",
//           modulusLength: 4096,
//           publicExponent: new Uint8Array([1, 0, 1]),
//           hash: { name: "SHA-256" },
//         },
//         true,
//         ["encrypt", "decrypt"]
//       );

//       // const publicKey = await window.crypto.subtle.exportKey("spki", keyPair.publicKey);
//       // const privateKey = await window.crypto.subtle.exportKey("pkcs8", keyPair.privateKey);

//       return {
//         publicKey: `-----BEGIN PUBLIC KEY-----
// MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAsCF/emL/a9J8H8MNx5ZS
// hEEtXqI2uXDioefOY37wiWGgeSHJ2iYosJtWF0bAEXCxoZlUfJTIY3zLpwp7dz24
// +2VUXSxVm4AExq/lV+8LCLx5AZ64P82PFfYX3uP1CzUZwCl4593ldVjJPKTHk5ec
// IYzHzFZvI6uLdXFV0arrfjd7JtYKaP9aLrbc2GmtNrPGvHtEd5oRjzk6D/qbIwO8
// QT7wANyx0WVbon7agP7kZ6/8W+S1KAYK4+J8pJKNXNld6ccgZ/vyZhOZXZcxwJoy
// PJMgIDqMkMTyTJmxmWIJeNvIxMriD1+zcXYKhCOWSPCK32BqNXd6CUXOVi2nMimN
// mc0QDv6e0ZZWnisgG740v+yrJVqHRSPZqrPt+9l67w4mTXLTkBxvU65tOmzcbIX/
// odvoZVqVGK4xBkEfX718LN8O2qegvNJN0/69edB1RLUobIU5Hef3Qz+MLG29yrWg
// znohlZtpPiYQ5Wd1FNCY1YCblVThVOBqwh7wiLWxSNO3u8Ni6oVq+21qhxLdHt/Q
// 6rm2bh7iABj/0oby5TE9M8/P5C6zFD7TXMqeyUPyguQMfLA46a/X8UXYBR5cRp/T
// o4HtdrgbtEql7C5IH6wnWXxczfwj56631+Vc8qRfDsY0nMCwYSkNgyK5NHUMjobS
// vAIAqeKR6OAVb42fIUi86I8CAwEAAQ==
// -----END PUBLIC KEY-----`,
//         // privateKey: convertToPEM(privateKey, "PRIVATE"),
//       };
//     } catch (error) {
//       console.error("Error generating RSA key pair:", error);
//     }
//   };

//   // const convertToPEM = (keyBuffer, type) => {
//   //   const binary = String.fromCharCode.apply(null, new Uint8Array(keyBuffer));
//   //   const base64 = window.btoa(binary);
//   //   return `-----BEGIN ${type} KEY-----\n${base64.match(/.{1,64}/g).join("\n")}\n-----END ${type} KEY-----`;
//   // };

  const handleGenerate = async () => {
    if (!inputText.trim()) {
      alert("Please enter cocktail names!");
      return;
    }

    const cocktailNames = inputText.split(",").map((name) => name.trim());
    if (cocktailNames.length > 5) {
      alert("You can generate up to 5 cocktails at a time!");
      return;
    }

    try {
      const response = await fetch(
        `https://ci.api.barsys.com/api/cocktails/json?q=${cocktailNames}`,{
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            accessToken: `${jwtToken}`,
          }
        }
      );
      if (!response.ok) throw new Error("Error fetching cocktails!");

      const cocktails = await response.json();
      setGeneratedJson(JSON.stringify(cocktails, null, 2));
    } catch (error) {
      alert(error.message);
    }
  };

  const handleSave = async () => {
    if (!generatedJson.trim()) {
      alert("Please generate JSON first!");
      return;
    }

    if (!jwtToken) {
      alert("JWT token not available. Please generate it first.");
      return;
    }

    try {
      const response = await fetch(
        "https://ci.api.barsys.com/api/cocktails/json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accessToken: `${jwtToken}`,
          },
          body: generatedJson,
        }
      );

      if (!response.ok) throw new Error("Error saving cocktails!");

      const result = await response.json();
      console.log(result);
      alert(`Cocktails saved with IDs: ${result.msg}`);
      
      setGeneratedJson("");
      
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.h1}>JSON Formatter</h1>
      <div style={styles.formGroup}>
        <label style={styles.label} htmlFor="input-text">
          Input Text
        </label>
        <textarea
          style={styles.textarea}
          id="input-text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Enter your text here..."
        />
      </div>
      <button
        style={{ ...styles.button, ...styles.generateBtn }}
        onMouseOver={(e) =>
          (e.target.style.backgroundColor = styles.generateBtnHover.backgroundColor)
        }
        onMouseOut={(e) =>
          (e.target.style.backgroundColor = styles.generateBtn.backgroundColor)
        }
        onClick={handleGenerate}
      >
        Generate
      </button>
      <div style={styles.formGroup}>
        <label style={styles.label} htmlFor="generated-json">
          Generated JSON
        </label>
        <textarea
          style={styles.textarea}
          id="generated-json"
          value={generatedJson}
          onChange={(e) => setGeneratedJson(e.target.value)}
          placeholder="Generated JSON will appear here..."
        />
      </div>
      <button
        style={{ ...styles.button, ...styles.saveBtn }}
        onMouseOver={(e) =>
          (e.target.style.backgroundColor = styles.saveBtnHover.backgroundColor)
        }
        onMouseOut={(e) =>
          (e.target.style.backgroundColor = styles.saveBtn.backgroundColor)
        }
        onClick={handleSave}
      >
        Save
      </button>
    </div>
  );
};

export default JsonFormatter;
